import PageContents from '@/types/PageContents';

// const distanceX = 7.5;

// const videoModalSize = {
//   height: 'calc(315px + 4rem)',
//   width: 'calc(560px + 4rem)',
// };

const pageContent: PageContents[] = [
  {
    button: {
      top: '47.4%',
      left: '67%',
      type: 'open',
      url: '/content/Meet_the_Expert.jpg',
      content: '',
    },
    modal: {
      content: '',
    },
  },
  {
    button: {
      top: '27%',
      left: '35%',
      type: 'open',
      url: '/content/Lunch_Symposium.jpg',
      content: '',
    },
    modal: {
      content: '',
    },
  },
];

export default pageContent;
